<template>
  <div class="web_box">
    <van-nav-bar
      title="确认订单"
      left-arrow
      @click-left="toBack"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>
    <receive-people :addressInfo="addressInfo" />
    <div class="order_box">
      <div class="order_details">
        <div class="img">
          <img :src="orderGoods.picHead" alt="" />
        </div>
        <div class="text_box">
          <h2>{{ orderGoods.title }}</h2>
          <p class="pro_number">产品编号：{{ orderGoods.deviceNo }}</p>
          <div class="price_num">
            <p class="price">
              &yen; <i>{{ orderGoods.price }}</i>
            </p>
            <p class="num">x{{ orderGoods.buyNum }}</p>
          </div>
        </div>
      </div>
      <van-divider class="divider_line" />
      <div class="item_cell">
        <span>产品总金额</span>
        <span>&yen; {{ totalPrice }}</span>
      </div>
      <van-divider class="divider_line" />
      <div class="item_cell">
        <span>运费</span>
        <span>&yen; 0.00</span>
      </div>
    </div>
    <div class="bottom">
      <div class="all_count">
        <p class="all_text">应付总额（含运费）：</p>
        <p class="all_price">&yen;{{ totalPrice }}</p>
      </div>
      <button class="submmit_btn" @click="toPay()">确认订单</button>
    </div>
    <van-action-sheet
      v-model="boxShow"
      :round="false"
      :lock-scroll="true"
      :close-on-click-overlay="false"
    >
      <div class="hidden_box">
        <div class="close_hidden_box" @click="boxShow = false">
          <van-icon name="cross" />
        </div>

        <div class="production_box">
          <div class="product_pic">
            <img :src="orderGoods.picHead" alt="图片描述" />
          </div>
          <div class="product_texe">
            <p class="title">{{ orderGoods.title }}</p>
            <span>产品编号：{{ orderGoods.deviceNo }}</span>
          </div>
        </div>
        <van-divider class="divider_line" />
        <div class="price">
          <p>&yen;{{ totalPrice }}</p>
        </div>
        <ul>
          <li @click="changePay(2)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>余额支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 2 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
          <li @click="changePay(1)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>微信支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 1 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
          <li @click="changePay(0)">
            <div class="letf_text">
              <i class="icon"></i>
              <p>支付宝支付</p>
            </div>
            <div class="radio_icon">
              <span
                class="icon "
                :class="changeIndex == 0 ? 'checked_true' : 'checked_false'"
              ></span>
            </div>
          </li>
          <van-divider class="divider_line" />
        </ul>
        <div class="save_btn" @click="payOrder()">
          <button>确定支付</button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
// orderPayment;
import {preCreate, create, orderPayment, findOrder} from "@/utils/api";
import {Toast} from "vant";
import receivePeople from "../../components/receivePeople/index";
export default {
  components: {
    receivePeople,
  },
  data() {
    return {
      boxShow: false,
      changeIndex: 2,
      addressInfo: {},
      orderGoods: "",
      discountRate: "",
      recommendRate: "",
      totalNum: "",
      totalPrice: "",
      orderNo: "",
    };
  },
  mounted() {
    this.geeData();
  },
  methods: {
    toPay() {
      this.boxShow = true;
      // this.$router.push("/pay");
    },
    // 创建订单接口；传入参数：头部token，
    // orderForms订单来源（PC、IOS、Android），
    // addressId收货地址记录id，
    // remark订单备注,
    // buyNumber购买数量，deviceId商品id
    payOrder() {
      Toast.loading({
        message: "创建订单中",
        forbidClick: true,
        duration: 0,
      });
      setTimeout(() => {
        // 创建订单
        let that = this;
        if (that.addressInfo) {
          create({
            orderForms: "PC",
            addressId: that.addressInfo.id,
            remark: "",
            buyNumber: that.$route.query.buyNum,
            deviceId: that.$route.query.goodsId,
          })
            .then((res) => {
              Toast.clear();
              that.orderNo = res.data.orderNo;
              Toast.loading({
                message: "正在支付，请稍等...",
                forbidClick: true,
                duration: 0,
              });
              // 支付接口
              setTimeout(() => {
                let obj = {
                  orderNo: that.orderNo,
                  price: that.totalPrice,
                  orderType: 1,
                };
                window.localStorage.setItem(
                  "productionitem",
                  JSON.stringify(obj)
                );
                if (this.changeIndex == 2) {
                  setTimeout(() => {
                    orderPayment({
                      payType: that.changeIndex,
                      orderNo: that.orderNo,
                      channel: "APP",
                      orderType: 1,
                    }).then((res) => {
                      findOrder({orderNo: that.orderNo})
                        .then((success) => {
                          Toast.clear();
                          if (success.map.status == "S") {
                            that.$router.replace({
                              path: "/pay",
                              query: {
                                type: "success",
                              },
                            });
                            this.boxShow = false;
                          } else {
                            that.$router.replace({
                              path: "/pay",
                              query: {
                                type: "error",
                                orderNo: that.orderNo,
                              },
                            });
                            this.boxShow = false;
                          }
                        })
                        .catch(() => {});
                      console.log(res);
                    }, 1000);
                  }).catch(() => {});
                } else {
                  Toast.clear();
                  window.android.pay(that.orderNo, that.changeIndex, 1);
                }
              }, 200);
            })
            .catch(() => {});
        } else {
          Toast({
            message: "请选择地址",
            duration: 500,
          });
          that.boxShow = false;
        }
      }, 500);
    },
    geeData() {
      preCreate({
        buyNum: this.$route.query.buyNum,
        goodsId: this.$route.query.goodsId,
      })
        .then((res) => {
          this.addressInfo = res.data.addressInfo;
          this.orderGoods = res.data.orderGoods;
          this.discountRate = res.data.discountRate;
          this.recommendRate = res.data.recommendRate;
          this.totalNum = res.data.totalNum;
          this.totalPrice = res.data.totalPrice;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePay(i) {
      this.changeIndex = i;
    },
    // preCreate
  },
};
</script>
<style lang="scss" scoped>
.order_box {
  width: 100%;
  background-color: #12172c;
  margin-top: 20px;
  .order_details {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 30px;
    .img {
      width: 160px;
      height: 160px;
      background-color: #374372;
    }
    .text_box {
      width: 498px;
      margin-left: 30px;
      h2 {
        font-size: 28px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 20px;
      }
      .pro_number {
        font-size: 24px;
        color: #37708f;
        margin-bottom: 20px;
      }
      .price_num {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .price {
          color: #00ccff;
          font-size: 28px;
          i {
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
          }
        }
        .num {
          font-size: 26px;
          color: #fff;
        }
      }
    }
  }
  .item_cell {
    padding: 0 30px;
    line-height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 26px;
      color: #37708f;
    }
    span:last-of-type {
      color: #00ccff;
    }
  }
}
.divider_line {
  margin: 0 30px !important;
  background-color: #374372;
  //   padding: 0 auto;
  color: #374372;
  border-color: #374372;
}
.bottom {
  position: fixed;
  width: 100%;
  height: 107px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  background-color: #232a44;
  align-content: center;
}
.all_count {
  margin-left: 30px;
  margin-top: 19px;
  .all_text {
    color: #7f89b3;
    font-size: 22px;
  }
  .all_price {
    color: #00ccff;
    font-size: 32px;
  }
}
.submmit_btn {
  width: 200px;
  height: 80px;
  margin-right: 30px;
  color: #fff;
  background-color: #00ccff;
  border: none;
  outline: none;
  border-radius: 112px;
  margin-top: 18px;
  font-size: 28px;
}
.hidden_box {
  background-color: #232a44;
  position: relative;
  .close_hidden_box {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 40px;
    color: #acafb8;
  }
  .production_box {
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: flex-end;
    padding: 80px 0 40px;
    .product_pic {
      width: 120px;
      height: 120px;
    }
    .product_texe {
      width: 80%;
      margin-left: 30px;
      .title {
        color: #00ccff;
        font-size: 28px;
        margin-bottom: 10px;
      }
      span {
        color: #37708f;
        font-size: 24px;
      }
    }
  }
  .price {
    width: 90%;
    margin: 0 auto;
    p {
      color: #00ccff;
      font-size: 56px;
      padding: 50px 0;
      text-align: center;
    }
  }
  ul {
    width: 90%;
    margin: 0 auto;
    li {
      width: 100%;
      height: 119px;
      line-height: 119px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .letf_text {
        .icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          background-repeat: no-repeat;
          margin-right: 20px;
          background-size: 100% 100%;
          vertical-align: sub;
        }

        p {
          display: inline-block;
          color: #00ccff;
          font-size: 28px;
        }
      }

      .radio_icon {
        .icon {
          display: inline-block;
          width: 32px;
          height: 32px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .checked_true {
          background-image: url("../../assets/address/checked_true.png");
        }
        .checked_false {
          background-image: url("../../assets/address/checked_false.png");
        }
      }
    }
    li:first-of-type > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon1.png");
    }
    li:nth-of-type(2) > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon2.png");
    }
    li:last-of-type > .letf_text > .icon {
      background-image: url("../../assets/pay/pay_icon3.png");
    }
  }
  .save_btn {
    widows: 100%;
    text-align: center;
    button {
      width: 90%;
      background: #00ccff;
      border-radius: 44px;
      height: 88px;
      text-align: center;
      border: none;
      outline: none;
      color: #fff;
      font-size: 28px;
      margin: 50px auto 40px;
    }
  }
}
</style>
